import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/operation/upload-banner`}
          component={lazy(() => import(`./operation/upload-banner`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/operation/manage-patch`}
          component={lazy(() => import(`./operation/manage-patch`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/operation/manage-surveys`}
          component={lazy(() => import(`./operation/manage-surveys`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/find-player`}
          component={lazy(() => import(`./support/find-player`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/find-guild`}
          component={lazy(() => import(`./support/find-guild`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/fetch-logs`}
          component={lazy(() => import(`./support/fetch-logs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/send-items`}
          component={lazy(() => import(`./support/send-items`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/rank-players`}
          component={lazy(() => import(`./support/rank-players`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/ban-players`}
          component={lazy(() => import(`./support/ban-players`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/manage-guilds`}
          component={lazy(() => import(`./support/manage-guilds`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/gm-tools`}
          component={lazy(() => import(`./support/gm-tools`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/gold-count`}
          component={lazy(() => import(`./support/gold-count`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/global-bot`}
          component={lazy(() => import(`./support/global-bot`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/global-bot-tool`}
          component={lazy(() => import(`./support/global-bot-tool`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/editor-info-json`}
          component={lazy(() => import(`./support/editor-info-json`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/paid-traffic-emails`}
          component={lazy(() => import(`./support/paid-traffic-emails`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/accounts-np`}
          component={lazy(() => import(`./support/accounts-np`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/ccu`}
          component={lazy(() => import(`./analytics/ccu`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/revenue`}
          component={lazy(() => import(`./analytics/revenue`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/new-players-revenue`}
          component={lazy(() => import(`./analytics/new-players-revenue`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/crm`}
          component={lazy(() => import(`./analytics/crm`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/item-amount`}
          component={lazy(() => import(`./analytics/item-amount`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/urlshorter-metrics`}
          component={lazy(() => import(`./analytics/urlshorter-metrics`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/royalarena-reports`}
          component={lazy(() => import(`./analytics/royalarena-reports`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/activeplayers-per-country`}
          component={lazy(() =>
            import(`./analytics/activeplayers-per-country`)
          )}
        />

        <Route
          path={`${APP_PREFIX_PATH}/analytics/items`}
          component={lazy(() => import(`./analytics/items`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analytics/reports`}
          component={lazy(() => import(`./analytics/reports`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support/all-emails`}
          component={lazy(() => import(`./support/all-emails`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}/operation`}
          to={`${APP_PREFIX_PATH}/operation/upload-banner`}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}/support`}
          to={`${APP_PREFIX_PATH}/support/find-player`}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}/analytics`}
          to={`${APP_PREFIX_PATH}/analytics/crm`}
        />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
